// import SigninForm from '../../components/signin/SigninForm';

const Signin = () => {
  return (
    <>
      <div className='container-fluid md:container mx-auto flex justify-center items-center h-[80vh]'>
        <div className='md:w-[45%] mx-auto text-center'>
          <h1 className='text-[1.5rem] leading-[4rem]'>
            <span className='font-bold'>
              Your account creation is under process.
            </span>{' '}
            <br /> Once the account is ready, you will receive an email with
            further instructions.
          </h1>
        </div>
      </div>
    </>
  );
};

export default Signin;
