import heroImage from '../assets/images/heroImage.png';
import b2bBanner1 from '../assets/images/b2b_banner_1.png';
import b2bBanner2 from '../assets/images/b2b_banner_2.png';
import b2bBanner3 from '../assets/images/b2b_banner_3.svg';
import b2bBanner4 from '../assets/images/b2b_banner_4.png';
import step1 from '../assets/images/b2b_step1.svg';
import step2 from '../assets/images/b2b_step2.svg';
import step3 from '../assets/images/b2b_step3.svg';
import step1Expanded from '../assets/images/b2b_step1_expanded.svg';
import step2Expanded from '../assets/images/b2b_step2_expanded.svg';
import step3Expanded from '../assets/images/b2b_step3_expanded.svg';
import step1Mobile from '../assets/images/b2b_step1_mobile.svg';
import step2Mobile from '../assets/images/b2b_step2_mobile.svg';
import step3Mobile from '../assets/images/b2b_step3_mobile.svg';
import b2bCompanies from '../assets/images/b2b_companies.png';
import privacyIcon1 from '../assets/images/privacy_icon_1.svg';
import privacyIcon2 from '../assets/images/privacy_icon_2.svg';
import privacyIcon3 from '../assets/images/privacy_icon_3.svg';
import IosStoreLogo from '../assets/images/ios_app_store_logo.png';
import AndroidStoreLogo from '../assets/images/android_app_store_logo.png';
import WhiteLogo from '../assets/images/white_logo.svg';
import GoLive1 from '../assets/images/go_live_1.svg';
import GoLive2 from '../assets/images/go_live_2.svg';
import GoLive3 from '../assets/images/go_live_3.svg';
import GoLive4 from '../assets/images/go_live_4.svg';
import PricingBG from '../assets/images/pricing_bg.svg';
import AboutUsBanner1 from '../assets/images/about_us_banner1.png';
import AboutUsBanner2 from '../assets/images/about_us_banner2.png';
import PersonalisedWebsite from '../assets/images/personalised_website-cropped.svg';
import contact from '../assets/images/contact.png';
import envelope from '../assets/images/envelope.svg';
import message from '../assets/images/message.png';
import entity from '../assets/images/entity.png';
export const images = {
  heroImage,
  b2bBanner1,
  b2bBanner2,
  b2bBanner3,
  b2bCompanies,
  step1,
  step2,
  step3,
  step1Expanded,
  step2Expanded,
  step3Expanded,
  step1Mobile,
  step2Mobile,
  step3Mobile,
  privacyIcon1,
  privacyIcon2,
  privacyIcon3,
  b2bBanner4,
  IosStoreLogo,
  AndroidStoreLogo,
  WhiteLogo,
  GoLive1,
  GoLive2,
  GoLive3,
  GoLive4,
  PricingBG,
  AboutUsBanner1,
  AboutUsBanner2,
  PersonalisedWebsite,
  contact,
  envelope,
  message,
  entity,
};
