import BarchartComp from '../../components/barChart/BarChart';
import Card from '../../components/card/Card';
import LineChartComp from '../../components/lineChart/LineChart';

const Dashboard = () => {
  return (
    <div className='flex flex-col gap-6'>
      <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-5'>
        <Card title='Total Customers' value='8465' />
        <Card title='Total Transactions' value='954' />
        <Card title='Partner Earnings' value='$45,231.45' isMoney />
        <Card title='Customer Earnings' value='$45,231.45' isMoney />
        <Card title='Enrolled Cards' value='513' />
        <Card title='Total Customers' value='8465' />
        <Card title='Total Transactions' value='954' />

        <Card title='Enrolled Cards' value='513' />
      </div>{' '}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
        <BarchartComp title='Customers Count' />
        <LineChartComp title='Transactions Count' />
      </div>
    </div>
  );
};

export default Dashboard;
