import React, { ChangeEvent, useState } from 'react';

const OrgData = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    displayName: 'Filipino Magazine',
    logo: null,
    logoPreview: '/saversLogo.svg', // Dummy logo path
    primaryColor: '#3498db',
    secondaryColor: '#2ecc71',
    backgroundColor: '#f5f5f5',
  });

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    // const file = e.target.files?.[0];
    // if (file) {
    //   setFormData((prev) => ({
    //     ...prev,
    //     logo: file,
    //     logoPreview: URL.createObjectURL(file),
    //   }));
    // }
  };

  const handleUpdate = () => {
    // Handle form submission or data update here
    console.log('Updated Data: ', formData);
    setIsEditing(false);
  };

  return (
    <div className='p-6 bg-[#f4f4f4] shadow rounded-lg w-[60%]'>
      <div className='flex justify-between items-center mb-6'>
        <h2 className='text-xl font-semibold'>Brand Information</h2>
        <button
          onClick={handleEditToggle}
          className='text-blue-500 hover:text-blue-700'
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </button>
      </div>

      <form className='space-y-4'>
        <div className='flex flex-col'>
          <label className='font-medium'>Organisation's Display Name</label>
          <input
            type='text'
            name='displayName'
            value={formData.displayName}
            onChange={handleInputChange}
            disabled={!isEditing}
            className='mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
        </div>

        <div className='flex flex-col'>
          <label className='font-medium'>Logo</label>
          <div className='flex items-center space-x-4 mt-1'>
            <img
              src={formData.logoPreview}
              alt='Current Logo'
              className='w-[12rem] object-cover rounded'
            />
            {isEditing && (
              <input
                type='file'
                name='logo'
                accept='image/*'
                onChange={handleLogoChange}
                className='border p-2 rounded'
              />
            )}
          </div>
        </div>

        <div className='flex flex-col'>
          <label className='font-medium'>Primary Colour</label>
          <div className='flex items-center mt-1'>
            <input
              type='text'
              name='primaryColor'
              value={formData.primaryColor}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
            <div
              className='h-6 w-6 ml-2 rounded'
              style={{ backgroundColor: formData.primaryColor }}
            />
          </div>
        </div>

        <div className='flex flex-col'>
          <label className='font-medium'>Secondary Colour</label>
          <div className='flex items-center mt-1'>
            <input
              type='text'
              name='secondaryColor'
              value={formData.secondaryColor}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
            <div
              className='h-6 w-6 ml-2 rounded'
              style={{ backgroundColor: formData.secondaryColor }}
            />
          </div>
        </div>

        {/* <div className='flex flex-col'>
          <label className='font-medium'>Background Colour</label>
          <div className='flex items-center mt-1'>
            <input
              type='text'
              name='backgroundColor'
              value={formData.backgroundColor}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
            <div
              className='h-6 w-6 ml-2 rounded'
              style={{ backgroundColor: formData.backgroundColor }}
            />
          </div>
        </div> */}

        {isEditing && (
          <button
            type='button'
            onClick={handleUpdate}
            className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
          >
            Update
          </button>
        )}
      </form>
    </div>
  );
};

export default OrgData;
