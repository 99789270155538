// import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { images } from '../../constants/images';

const QR = () => {
  const url =
    'https://play.google.com/store/apps/details?id=com.madrewards.savemate&hl=en_IN';
  // const [url, setUrl] = useState('https://play.google.com/store/apps/details?id=com.madrewards.savemate&hl=en_IN');
  // const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  //     setUrl(e.target.value);
  //   };

  return (
    <div className='flex flex-col items-center p-6 bg-white rounded-lg m-auto w-[85%] mt-[2rem] '>
      {/* Encouraging One-Liner */}
      <p className='text-2xl leading-[2.5rem] font-semibold text-center mb-4'>
        Stay connected on the go! Download our mobile app to easily monitor your
        portal and manage everything with a tap.
      </p>

      {/* QR Code Section */}
      <div className='flex space-x-8'>
        <div className='flex flex-col items-center mx-[4rem] my-[3rem]'>
          <a
            href='https://apps.apple.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={images.IosStoreLogo}
              alt='Download on the App Store'
              className='w-32'
            />
          </a>
          {url && (
            <QRCode
              value={url}
              size={256} // Size of the QR code
              bgColor='#ffffff' // Background color
              fgColor='#000000' // Foreground color
              level='Q' // Error correction level
              includeMargin={true} // Include margin around the QR code
            />
          )}
          <p className='text-base font-semibold mt-3'>Scan for iOS</p>
        </div>
        <div className='flex flex-col items-center mx-[4rem] my-[3rem]'>
          <a
            href='https://play.google.com/store'
            className='m-auto'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={images.AndroidStoreLogo}
              alt='Get it on Google Play'
              className='w-32'
            />
          </a>
          {url && (
            <QRCode
              value={url}
              size={256} // Size of the QR code
              bgColor='#ffffff' // Background color
              fgColor='#000000' // Foreground color
              level='Q' // Error correction level
              includeMargin={true} // Include margin around the QR code
            />
          )}
          <p className='text-base font-semibold mt-3'>Scan for Android</p>
        </div>
      </div>
    </div>
  );
};

export default QR;
