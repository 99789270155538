import React, { ChangeEvent, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { SubmitHandler, useForm } from 'react-hook-form';
// import RedStar from "../../components/RedStar";
// import { countries } from '../../assets/dummy';
const CompanyInfo = () => {
  const initialData = {
    companyName: 'Filipino Magazine',
    companyReg: '1234567890',
    address: '123, Techeshi Castle',
    city: 'Techville',
    state: 'TS',
    zipCode: '12345',
    country: 'Techland',
    companyEmail: 'filipinomagazine@gmail.com',
    phone: '+1234567890',
  };

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  //   const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
  //     // const file = e.target.files?.[0];
  //     // if (file) {
  //     //   setFormData((prev) => ({
  //     //     ...prev,
  //     //     logo: file,
  //     //     logoPreview: URL.createObjectURL(file),
  //     //   }));
  //     // }
  //   };

  const handleUpdate = () => {
    // Handle form submission or data update here
    console.log('Updated Data: ', formData);
    setIsEditing(false);
  };

  return (
    <>
      <h2 className=']font-semibold mb-8 text-[2rem]'>Company Information</h2>

      <div className='p-6 bg-[#f4f4f4] shadow rounded-lg w-[100%]'>
        <div className='flex justify-end items-center mb-6'>
          <></>
          <button
            onClick={handleEditToggle}
            className='text-blue-500 hover:text-blue-700'
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </button>
        </div>

        <form className='space-y-4'>
          <div className='flex flex-col'>
            <label className='font-medium'>Company Name</label>
            <input
              type='text'
              name='companyName'
              value={formData.companyName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>Company Registration</label>
            <input
              type='text'
              name='companyReg'
              value={formData.companyReg}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>Address</label>
            <input
              type='text'
              name='address'
              value={formData.address}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>City</label>
            <input
              type='text'
              name='city'
              value={formData.city}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>State</label>
            <input
              type='text'
              name='state'
              value={formData.state}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>ZIP Code</label>
            <input
              type='text'
              name='zipCode'
              value={formData.zipCode}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>Country</label>
            <input
              type='text'
              name='country'
              value={formData.country}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>Company Email</label>
            <input
              type='email'
              name='companyEmail'
              value={formData.companyEmail}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>

          <div className='flex flex-col'>
            <label className='font-medium'>Phone</label>
            <input
              type='tel'
              name='phone'
              value={formData.phone}
              onChange={handleInputChange}
              disabled={!isEditing}
              className='p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
            />
          </div>
          <div className='flex justify-center'>
            {isEditing && (
              <button
                type='button'
                onClick={handleUpdate}
                className='px-6 py-3 text-[1.25rem] bg-primary text-white rounded hover:bg-primary'
              >
                Update
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default CompanyInfo;
