import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../assets/SVG-Icons';
import Button from '../button/Button';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const NavBarLandingPage = () => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [isB2B, setIsB2B] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsB2B(true);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      className={`relative flex items-center justify-between my-8 mx-4 md:mx-14 gap-6 smd:gap-4 md:gap-4 ${
        pathname === '/signup' ? 'mb-0 mt-6' : ''
      }`}
      style={{ maxWidth: '100%', overflowX: 'hidden' }}  
    >
      {/* Logo Section */}
      <div className='w-full smd:w-[12rem] md:w-[14rem] xmd:w-[19.3rem] flex justify-between items-center z-50 md:pb-2'>
        <Link className='w-[90%] md:w-auto' to={'/'}>{Icons.logo({width:'300'})}</Link>

        {/* Hamburger Icon */}
        {(pathname !== '/signup') && (
          <button
            className='md:hidden text-3xl z-50'
            onClick={toggleMenu}
            aria-label='Toggle Menu'
          >
            {isMenuOpen ? Icons.closeIcon() : (
              <svg
                className='w-8 h-8'
                fill='#000000'
                stroke='#000000'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            )}
          </button>
        )}
      </div>

      {/* Navigation Menu */}
      {pathname !== '/signup' && (
        <nav
          className={`fixed top-0 left-0 w-full h-full bg-white z-40 transform transition-transform duration-300 md:static md:flex md:items-center md:justify-center md:w-auto ${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
          }`}
        >
          <ul className='flex flex-col h-full mt-[10rem] md:mt-0 md:flex-row items-center justify-start md:justify-center gap-5 smd:gap-2 md:gap-5'>
            {isB2B && (
              <>
                <li
                  onClick={() => {
                    if(isMenuOpen) {toggleMenu()};
                    if (currentPath === '/') {
                      document.getElementById('partner-with-us')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#partner-with-us');
                    }                  
                  }}
                  className='text-xl md:text-base smd:mx-0 xmd:mx-2 md:mx-0 font-semibold cursor-pointer'
                >
                  Partner with us
                </li>
                <li
                  onClick={() => {
                    if(isMenuOpen) {toggleMenu()};
                    if (currentPath === '/') {
                      document.getElementById('contact-form')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#contact-form');
                    }       
      
                  }}
                  className='text-xl md:text-base smd:mx-0 xmd:mx-2 md:mx-0 font-semibold cursor-pointer'
                >
                  Contact us
                </li>
                <li
                  onClick={() => {
                    if(isMenuOpen) {toggleMenu()};
                    if (currentPath === '/') {
                      document.getElementById('how-it-works')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#how-it-works');
                    }    
                  }}
                  className='pb-[8rem] md:pb-0 text-xl smd:mx-0 md:text-base xmd:mx-2 md:mx-0 font-semibold cursor-pointer'
                >
                  How it works
                </li>
              </>
            )}
            <li className='md:mr-8'></li>
            <li>
              <button
                onClick={() => {
                if(isMenuOpen) {toggleMenu()};
                 navigate('/signin')}}
                className='text-center whitespace-nowrap font-semibold border-[3px] border-secondary-400 rounded-[10px] smd:px-6 xmd:px-8 py-2 xmd:py-3 px-[4.75rem] md:px-20 border-secondary text-secondary'
              >
                LOG IN
              </button>
            </li>
            <li>
              <Button
                primary
                className='px-[3rem] md:px-6 xmd:px-8'
                onClick={() => navigate('/signup')}
              >
                <span className='stroke-white hover:stroke-primary'>
                  {Icons.rocket('currentColor')}
                </span>
                <span>Get Started</span>
              </Button>
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default NavBarLandingPage;