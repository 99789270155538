import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import Avatar from '../avatar/Avatar';
// import SearchInput from '../searchInput/SearchInput';

interface Props {
  toggleDrawer: () => void;
  isDrawerOpen: boolean;
}

const Navbar = ({ isDrawerOpen, toggleDrawer }: Props) => {
  const { user } = useAppSelector((state: RootState) => state.auth);
  return (
    <>
      <nav
        className={`grid grid-cols-2 place-content-center  h-16 w-full bg-primaryBg-50 px-3 md:px-5 lg:px-7`}
      >
        {/*menu-icon div*/}
        <div className='flex items-center '>
          <button
            onClick={toggleDrawer}
            className='text-gray-900 focus:outline-none pr-7'
          >
            <svg
              className='w-6 h-6'
              fill='#333399'
              stroke='#333399'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </button>

          <div className='hidden lg:block w-2/3'>
            {/* <SearchInput /> */}
            <h4 className='text-[1.25rem]'>Partner Name</h4>
          </div>
        </div>
        <div className='flex space-x-1 justify-end'>
          <div className='hidden md:block whitespace-nowrap text-primary'>
            <p className='font-bold text-sm '>{user?.email}</p>
            <p className=' text-xs font-normal'>Software Engineer</p>
          </div>
          <Avatar
            src='https://avatars.githubusercontent.com/u/127940886?v=4&size=64'
            alt='User Avatar'
          />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
