import React from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router-dom';
// import { RootState } from '../../app/store';

interface Props {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  // const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  // return isAuthenticated ? <>{children}</> : <Navigate to='/signin' />;
  return <>{children}</>;
};

export default ProtectedRoute;
