import React, { useState } from 'react';
import Button from '../../components/button/Button';

interface FormState {
  email: string;
  subject: string;
  message: string;
}

const Support: React.FC = () => {
  const [formState, setFormState] = useState<FormState>({
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formState);
  };

  return (
    <div className='flex flex-col p-4 w-full max-w-md mx-auto bg-white rounded-lg mt-8'>
      <h2 className='text-2xl font-semibold text-gray-700 mb-4'>Need Help?</h2>
      <p className='text-sm text-gray-500'>
        We're here for you! Contact us with any questions or technical issues,
        and we'll be happy to assist.
      </p>
      <form onSubmit={handleSubmit} className='mt-4'>
        <label htmlFor='email' className='block'>
          Email
          <input
            type='email'
            name='email'
            id='email'
            value={formState.email}
            onChange={handleChange}
            placeholder='Email'
            className='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
          />
        </label>
        <label htmlFor='subject' className='block mt-4'>
          Subject
          <input
            type='text'
            name='subject'
            id='subject'
            value={formState.subject}
            onChange={handleChange}
            placeholder='Subject'
            className='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
          />
        </label>
        <label htmlFor='message' className='block mt-4'>
          Message
          <textarea
            name='message'
            id='message'
            value={formState.message}
            onChange={handleChange}
            placeholder='Message'
            rows={6}
            className='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
          />
        </label>
        <Button type='submit' primary className='w-full mt-4'>
          SUBMIT
        </Button>
      </form>
    </div>
  );
};

export default Support;
