import { PropsWithChildren, useState } from 'react';
import { Outlet } from 'react-router';
import Navbar from '../components/navBar/NavBar';
import Sidebar from '../components/sideBar/SideBar';

const LayoutDashboard: React.FC<PropsWithChildren> = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <main className='flex font-Montserrat leading-[19.49px] min-h-screen w-full'>
      <Sidebar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <div className='flex flex-col w-full '>
        <Navbar isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
        <div className='py-5 px-3 md:px-5 lg:px-7'>
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default LayoutDashboard;
