import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { AppThunk } from '../app/store';

interface AuthState {
  isAuthenticated: boolean;
  user: { email: string; password: string } | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: state => {
      state.isAuthenticated = false;
      state.user = null;
    },
    registrationStart: state => {
      state.loading = true;
      state.error = null;
    },
    registrationSuccess: (
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    registrationFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  login,
  logout,
  registrationStart,
  registrationSuccess,
  registrationFailure,
} = authSlice.actions;
export const authReducer = authSlice.reducer;

export const logoutAndRedirect =
  (navigate: NavigateFunction): AppThunk =>
  dispatch => {
    dispatch(logout());
    navigate('/');
  };

export const registerUser =
  (userData: any, navigate: NavigateFunction): AppThunk =>
  async dispatch => {
    dispatch(registrationStart());
    try {
      // const response = await registerUserApi(userData); // Simulate API call
      // dispatch(registrationSuccess(response));
      navigate('/dashboard'); // Redirect on success
    } catch (error: any) {
      dispatch(registrationFailure(error.message));
    }
  };

export default authSlice.reducer;
