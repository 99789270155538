import LayoutDashboard from './layout/LayoutDashboard';
import Dashboard from './pages/dashboard/Dashboard';
import Reporting from './pages/reporting/Reporting';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Signin from './pages/signin/Signin';
import SignUp from './pages/signup/Main';
import UserManagement from './pages/userManagement/UserManagement';
// import LandingPage from './pages/landingPage/LandingPage';
import MarketingMaterial from './pages/marketingMaterial/MarketingMaterial';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import { Suspense } from 'react';
import Spinner from './components/spinner/Spinner';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import RecentTransactions from './pages/recentTransactions/RecentTransactions';
import SubscriptionPayments from './pages/subscriptionPayments/SubscriptionPayments';
import Support from './pages/support/Support';
import Profile from './pages/profile/Profile';
import Layout from './layout/Layout';
import Faq from './pages/faq/Faq';
import QR from './pages/marketingMaterial/QR';
import Email from './pages/marketingMaterial/Email';
import SocialMedia from './pages/marketingMaterial/SocialMedia';
import Print from './pages/marketingMaterial/Print';
import CompanyInfo from './pages/companyInfo/CompanyInfo';
import ProgramBranding from './pages/programBranding/ProgramBranding';
import PayoutAccount from './pages/payoutAccount/PayoutAccount';
import B2BLanding from './pages/b2b/B2BLanding';
import Invoices from './pages/invoices/Invoices';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import PrivacyPolicyPage from './pages/privacyPolicy/PrivacyPolicyPage';
import AboutUs from './pages/aboutUs/AboutUs';
import FaqPage from './pages/faq/FaqPage';

export default function App() {
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<B2BLanding />} />
            <Route path='/signin' element={<Signin />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/business-faq' element={<FaqPage />} />
            <Route
              path='/t&c-privacy'
              element={<PrivacyPolicyPage scrollToId='privacy' />}
            />
          </Route>
          <Route
            element={
              <ProtectedRoute>
                <LayoutDashboard />
              </ProtectedRoute>
            }
          >
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/marketing-material/qr' element={<QR />} />
            <Route path='/marketing-material/email' element={<Email />} />
            <Route
              path='/marketing-material/social-media'
              element={<SocialMedia />}
            />
            <Route path='/marketing-material/print' element={<Print />} />
            <Route path='/marketing-material' element={<MarketingMaterial />} />
            <Route path='/user-management' element={<UserManagement />} />
            <Route path='/reporting' element={<Reporting />} />
            <Route path='/company-info' element={<CompanyInfo />} />
            <Route path='/program-branding' element={<ProgramBranding />} />
            <Route path='/subscription' element={<SubscriptionPayments />} />
            <Route path='/payout-account' element={<PayoutAccount />} />
            <Route path='/invoices' element={<Invoices />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route
              path='/recent-transactions'
              element={<RecentTransactions />}
            />
            <Route
              path='/subscription-payments'
              element={<SubscriptionPayments />}
            />
            <Route path='/support' element={<Support />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/faq' element={<Faq />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
