
interface EnvConfig {
    REACT_APP_ENV: string;
    REACT_APP_STRIPE_KEY: string;
    REACT_APP_ADMIN_BASE_URL: string;
    REACT_APP_ADMIN_X_API_KEY: string;
    REACT_APP_API_BASE_URL: string;
    REACT_APP_API_X_API_KEY: string;
  }

const envConfig: EnvConfig = {
    REACT_APP_ENV:'prod',
    REACT_APP_STRIPE_KEY:'pk_live_51PifMXKPo0sZkpYAEZY7MKtAc3Rdf8VWRvZzEPTIGMlp3qEFgn27Hke4KE86X8pKzvVH4GSlXZkAofpaFTKAPp8o00psvEo90D',
    REACT_APP_ADMIN_BASE_URL:'https://adminapi.madrewards.com/cpp/v1',
    REACT_APP_ADMIN_X_API_KEY:'3vSImub1KH6MkYZMOhsai52qXUBwHc5S3Cb2di6q',
    REACT_APP_API_BASE_URL:'https://prodapi.madrewards.com/clo/v1',
    REACT_APP_API_X_API_KEY:'40TuzE1zkm6Kx0D0uyzSt7IsBI3IGoz8fDCiHgJ6'
}

export default envConfig;


