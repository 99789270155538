import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import envConfig from '../../utils/config';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { MdOutlineDone } from 'react-icons/md';


const stripeKey = envConfig.REACT_APP_STRIPE_KEY;
const xApiKey = envConfig.REACT_APP_ADMIN_X_API_KEY;
const stripePromise = loadStripe(`${stripeKey}`);


// Move fetchClientSecret outside of the component
const fetchClientSecret = async () => {
  try {
    const partnerId = localStorage.getItem('partnerId');
    const res = await fetch(`${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/pm/c`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${xApiKey}`,
      },
      body: JSON.stringify({ partnerId }),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error('Failed to fetch client secret');
  }
};

interface PaymentProps {
  paymentSessionId: string | null;
}

const Payment: React.FC<PaymentProps> = ({ paymentSessionId }) => {
  const [stripe, setStripe] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const fetchSessionStatus = async (paymentSessionId: string) => {
    console.log('DATA', JSON.stringify({ sessionId: paymentSessionId }));

    try {
      const result = await fetch(
        `${envConfig.REACT_APP_ADMIN_BASE_URL}/pmo/pm/g`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': `${xApiKey}`,
          },
          body: JSON.stringify({ sessionId: paymentSessionId }),
        }
      );

      const data = await result.json();
      console.log(61, 'Session Status Response:', data);

      if (data.status === 'complete') {
        setStatus('complete');
        setCustomerEmail(data.customerEmail);
        setShowSuccessModal(true);
      } else if (data.status === 'open') {
        setStatus('open');
      }
    } catch (error) {
      setError('Failed to fetch session status');
    }
  };

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
      if (paymentSessionId) {
        fetchSessionStatus(paymentSessionId);
      }
    };
    initializeStripe();
    console.log(error, status, customerEmail); // Logging to avoid unused variable warnings
  }, [paymentSessionId, error, status, customerEmail]); // Include all dependencies

  useEffect(() => {
    const initializeCheckout = async () => {
      if (stripe) {
        try {
          const clientSecret = await fetchClientSecret();
          if (!clientSecret) throw new Error('Missing client secret');

          const checkout = await stripe.initEmbeddedCheckout({
            clientSecret,
          });

          checkout.mount('#checkout');

          checkout.on('statusChange', (event: any) => {
            if (event.status === 'complete') {
              setCustomerEmail(event.customer_email);
              setStatus('complete');
              setShowSuccessModal(true); // Show modal on success
            }
          });
        } catch (error: any) {
          setError('Failed to initialize checkout: ' + error.message);
        }
      }
    };

    initializeCheckout();
  }, [stripe]); // Remove fetchClientSecret from dependencies

  return (
    <div>
      {!showSuccessModal && (
        <>
          <div id='checkout'>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ fetchClientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
          {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
        </>
      )}

      {showSuccessModal && (
        <div className='md:mt-[5rem]'>
          <div className='py-6 md:py-[3rem] md:px-[4rem] px-4' style={modalContentStyle}>
            <MdOutlineDone style={LogoStyle} />
            <p className='mb-6 md:px-[5rem]' style={textStyle}>
              Thank you for pre-registering! We&apos;re excited to offer you the
              opportunity to be among the first to roll out your own Savers Club
              Program.
            </p>
            <p className='mb-6 md:px-[2rem]' style={textStyle}>
              In the next 24 hours, you will receive an email to validate your
              information and with further instructions on next steps. If you
              don&apos;t receive an email from us please check your spam folder.
              If you still can&apos;t find the email, please contact us at{' '}
              <span className='text-blue-800'>registration@savers.club</span>
            </p>
            <p style={textStyle}>
              As we prepare your program to go live over the next 4-6 weeks, our
              team will reach out to you with all the essential details.
            </p>
            <p className='mt-6' style={textStyle}>
              Stay tuned!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const LogoStyle: React.CSSProperties = {
  color: 'white',
  background: '#66CC99',
  borderRadius: '50%',
  width: '6rem',
  height: '6rem',
  padding: '1rem',
  margin: '0rem auto 2rem',
};

const modalContentStyle: React.CSSProperties = {
  border: '1px solid lightgray',

  textAlign: 'center',
};

const textStyle: React.CSSProperties = {
  fontSize: '16px',
  lineHeight: '28px',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: '500',
  color: 'black',
};

export default Payment;
