import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

interface FormInput {
  email: string;
  terms: boolean;
}

const ForgotPasswordForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>();

  const onSubmit: SubmitHandler<FormInput> = data => {
    console.log(data);
  };

  return (
    <section>
      <div className='flex flex-col h-[75.5vh] items-center bg-primaryBg-50 justify-center py-8 mx-auto lg:py-0'>
        <div className='w-full p-6 rounded-lg md:mt-0 sm:max-w-md sm:p-8'>
          <h1 className='mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>
            Forgot your password?
          </h1>
          <p className='font-light text-gray-500'>
            Don't fret! Just type in your email and we will send you a code to
            reset your password!
          </p>
          <form
            className='mt-4 space-y-4 lg:mt-5 md:space-y-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <label
                htmlFor='email'
                className='block mb-2 text-sm font-medium text-gray-900'
              >
                Your email
              </label>
              <input
                type='email'
                id='email'
                {...register('email', { required: 'Email is required' })}
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary block w-full p-2.5 ${
                  errors.email && 'border-red-500'
                }`}
                placeholder='name@company.com'
              />
              {errors.email && (
                <p className='text-red-500 text-sm'>{errors.email.message}</p>
              )}
            </div>
            {/* <div className='flex items-start'>
              <div className='flex items-center h-5'>
                <input
                  id='terms'
                  type='checkbox'
                  {...register('terms', { required: 'You must accept the terms and conditions' })}
                  className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300'
                />
              </div>
              <div className='ml-3 text-sm'>
                <label htmlFor='terms' className='font-light text-gray-500 dark:text-gray-300'>
                  I accept the{' '}
                  <Link className='font-medium text-primary-600 hover:underline' to='#'>
                    Terms and Conditions
                  </Link>
                </label>
              </div>
            </div>
            {errors.terms && <p className='text-red-500 text-sm'>{errors.terms.message}</p>} */}
            <button
              type='submit'
              className='w-full text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center'
            >
              Reset password
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordForm;
