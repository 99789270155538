import React from 'react';
const templates = [
  {
    id: 1,
    src: 'https://i.pinimg.com/736x/bb/8c/04/bb8c043dac3b7f08366b663743640d17.jpg',
    alt: 'Email Template 1',
  },
  {
    id: 2,
    src: 'https://www.getvero.com/wp-content/uploads/2020/01/image-5-2.jpg',
    alt: 'Email Template 2',
  },
  { id: 3, src: 'template3.jpg', alt: 'Email Template 3' },
  // Add more templates as needed
];
const Email = () => {
  return (
    <div className='container mx-auto p-8'>
      <h1 className='text-3xl font-bold mb-[5rem]'>
        Download our expertly crafted email templates and streamline your email
        campaigns.
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        {/* Left side with instructions */}
        <div>
          <h2 className='text-xl font-semibold mb-4'>Instructions:</h2>
          <ul className='list-disc list-inside space-y-2'>
            <li>Download the desired email template.</li>
            <li>Paste the template content into your email body.</li>
            <li>Edit the text and images to match your message.</li>
            <li>Send your email with a polished, professional look.</li>
          </ul>
        </div>

        {/* Right side with image gallery */}
        <div className='grid grid-cols-2 gap-4'>
          {templates.map(template => (
            <div key={template.id} className='border rounded overflow-hidden'>
              <img
                src={template.src}
                alt={template.alt}
                className='w-full h-auto'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Email;
