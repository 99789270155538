import React from 'react';
// import Button from '../../components/button/Button';

interface FormState {
  readonly email: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
}

const Profile: React.FC = () => {
  const [formState, setFormState] = React.useState<FormState>({
    email: '',
    mobilePhone: '',
    firstName: '',
    lastName: '',
  });

  const [isChanged, setIsChanged] = React.useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState(prevState => {
      const newState = {
        ...prevState,
        [e.target.name]: e.target.value,
      };

      // Check if any input except email has changed
      setIsChanged(
        newState.mobilePhone !== '' ||
          newState.firstName !== '' ||
          newState.lastName !== ''
      );

      return newState;
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Form Data:', formState);
  };

  return (
    <>
      <h2 className='text-3xl mb-10 ml-10 mt-6 font-bold'>Profile</h2>
      <div className='w-fit mx-auto mt-8'>
        <form onSubmit={handleSubmit}>
          <div className='flex gap-6 mb-4'>
            <div className='flex flex-col'>
              <label className='text-black inline' htmlFor='email'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formState.email}
                placeholder='Email Address'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 p-2.5 w-80'
                readOnly
                disabled
              />
            </div>

            <div className='flex flex-col'>
              <label className='text-black' htmlFor='mobilePhone'>
                Mobile Phone
              </label>
              <input
                type='tel'
                id='mobilePhone'
                name='mobilePhone'
                value={formState.mobilePhone}
                onChange={handleChange}
                placeholder='Mobile Phone'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 p-2.5 w-80'
              />
            </div>
          </div>

          <div className='flex gap-6'>
            <div className='flex flex-col'>
              <label className='text-black' htmlFor='firstName'>
                First Name
              </label>
              <input
                type='text'
                id='firstName'
                name='firstName'
                value={formState.firstName}
                onChange={handleChange}
                placeholder='First Name'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 p-2.5 w-80'
              />
            </div>

            <div className='flex flex-col'>
              <label className='text-black' htmlFor='lastName'>
                Last Name
              </label>
              <input
                type='text'
                id='lastName'
                name='lastName'
                value={formState.lastName}
                onChange={handleChange}
                placeholder='Last Name'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mt-2 p-2.5 w-80'
              />
            </div>
          </div>

          <button
            type='submit'
            className={`w-[10rem] mt-6 mx-auto border px-4 py-2 rounded text-white ${
              isChanged ? 'bg-primary' : 'bg-gray-500'
            }`}
          >
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
};

export default Profile;
