import React from 'react';

interface Props {
  src: string;
  alt: string;
}

// "Avatar" renders an image element with custom styling and takes props "src" and "alt" for the image source and alternative
function Avatar({ ...rest }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img className='w-8 h-8 rounded-lg object-cover ml-1' {...rest} />
  );
}

export default Avatar;
