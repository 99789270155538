import { Action, configureStore } from '@reduxjs/toolkit';
import { authReducer } from '../features/authSlice';
import { searchReducer } from '../features/searchSlice';
import { thunk, ThunkAction } from 'redux-thunk';
// ...

export const store = configureStore({
  reducer: { auth: authReducer, search: searchReducer },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
